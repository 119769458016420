import React from 'react';
import giftImage from 'assets/img/earn-rewards/gift-card.jpg';
import howItWorks from 'assets/img/earn-rewards/how-It-works.png';
import pinTheLook from 'assets/img/earn-rewards/pin-the-look.jpg';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import classes from './Redeem.module.scss';
import { routesByName } from '../../../../constants/routes';
import personImg from '../../../../assets/img/earn-rewards/personByWorking.jpg';
import ptlHome from '../../../../assets/img/earn-rewards/ptl-home.png';
import SpriteIcon from '../../../../components/ui/SpriteIcon';

const RedeemPage = () => {
  return (
    <>
      <h1 className={classes.redeemHeader}>EARN & REDEEM POINTS</h1>
      <section className={clsx(classes.contentBlock, classes.redeemSection)}>
        <div className={classes.redeemRow}>
          <div className={clsx(classes.mainImageContainer)}>
            <img src={giftImage} alt=" gift card" />
          </div>
          <div className={classes.redeemTextContainer}>
            <h2 className={classes.mainTitle}>EARN POINTS WHILE YOU STYLE!</h2>
            <p className={classes.redeemSubTitle}>
              Curate Look Boards showing how to Get the Look of your favorite interior images.
            </p>
            <h2 className={clsx(classes.mainTitle, classes.redeemEarnTitle)}>
              EARN a $100 Gift Card for Every 100 Points!
            </h2>
          </div>
        </div>
        <div className={clsx(classes.redeemRow, classes.reverseRow)}>
          <div className={classes.redeemTextContainer}>
            <h2 className={classes.mainTitle}>HOW IT WORKS</h2>
            <ul className={classes.howItWorksList}>
              <li>
                <span>1</span> Get the{' '}
                <Link className="ml-1" to={routesByName.getThePin}>
                  Chrome Pin Extension
                </Link>
              </li>
              <li>
                <span>2</span>Go to{' '}
                <Link to={routesByName.curateTheLook.index} className="ml-1">
                  Curate the Look
                </Link>
              </li>
              <li>
                <span>3</span> Select an image from our database or Pin a new one
              </li>
              <li>
                <span>4</span> Curate the Look by dragging and dropping products into the Look Board
                template
              </li>
              <li>
                <span>5</span> Earn 5 Points for every approved Look Board you curate
              </li>
              <li>
                <span>6</span> Earn More Points for Sharing Look Boards on Social Media
              </li>
            </ul>
          </div>
          <div className={clsx(classes.mainImageContainer)}>
            <img src={howItWorks} alt=" gift card" />
          </div>
        </div>
        <div className={classes.redeemRow}>
          <div className={classes.mainImageContainer}>
            <img src={personImg} alt="personWorking" />
          </div>
          <div className={classes.redeemTextContainer}>
            <h2 className={classes.mainTitle}>EARN POINTS</h2>
            <p className={classes.defaultText}>
              Earn <strong>5 POINTS</strong> for every approved Look Board you curate.
            </p>
            <h2 className={classes.mainTitle}>SOCIAL SHARING POINTS</h2>
            <p className={classes.defaultText}>
              Earn <strong>5-50 POINTS</strong> for Sharing our Look Boards on Instagram or
              Facebook*
            </p>
            <ul className={classes.earnList}>
              <li>
                <span className={classes.point} /> 5 Points for under 1,000 Followers
              </li>
              <li>
                <span className={classes.point} /> 10 Points for 1,000+ Followers
              </li>
              <li>
                <span className={classes.point} />
                25 Points for over 25,000 Followers
              </li>
              <li>
                <span className={classes.point} />
                50 Points for 100,000+ Followers
              </li>
            </ul>
            <h3 className={classes.mainTitle}>TAG US TO EARN POINTS</h3>
            <p className={clsx(classes.tag, 'mt-1')}>
              Instagram: <span>@pinthelooklover</span>
            </p>
            <p className={classes.tag}>
              Facebook: <span>@pinthelookdecor</span>
            </p>
            <p className={clsx(classes.additional, 'mt-1')}>*Points are per look board shared</p>
          </div>
        </div>
        <div className={clsx(classes.redeemRow, classes.reverseRow)}>
          <div className={classes.redeemTextContainer}>
            <h2 className={clsx(classes.mainTitle, 'mb-6')}>TRACK & REDEEM POINTS</h2>
            <h3 className={clsx(classes.smallTitle, 'mb-1')}>TRACK POINTS</h3>
            <p className={classes.defaultText}>
              Click the STAR <SpriteIcon className={classes.star} name="borderedStar" size="lg" />{' '}
              icon in your Dashboard anytime to see your progress
            </p>
            <h3 className={clsx(classes.smallTitle, 'mb-1 mt-6')}>REDEEM POINTS</h3>
            <p className={classes.defaultText}>
              Once you reach 100 points, you can redeem them for a Gift Card at
            </p>
            <a href="https://pinthelook.store">
              <img className={classes.logo} src={ptlHome} alt="personWorking" />
            </a>
          </div>
          <div className={clsx(classes.mainImageContainer)}>
            <img src={pinTheLook} alt="pin the look" />
          </div>
        </div>
      </section>
    </>
  );
};

export default RedeemPage;
